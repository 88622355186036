<!-- <app-system-notification></app-system-notification> -->
<app-public-header [showLoginButton]="true"></app-public-header>
<div
  class="bg-image-gradient height-100vh with-header overflow-auto p-3 p-sm-5 d-flex flex-column align-items-center justify-content-center"
>
  <!-- CONTENT -->
  <div class="w-100 widescreen-scale">
    <h1 class="mb-1 text-center font-weight-bold plexe-menu-header-1">Forgot password</h1>
    <p class="text-center font-weight-bold plexe-menu-header-2">Reset your password</p>
    <div [hidden]="isSmsLogin">
      <form (ngSubmit)="forgotPassword()" [formGroup]="forgotPasswordForm" novalidate>
        <div
          class="d-flex flex-column py-0 flex-spacer mt-5 rounded-xl bg-white p-3 p-sm-5 mx-auto"
          style="max-width: 500px;"
        >
          <div class="form-group flex-spacer">
            <div class="mb-4 position-relative">
              <h4 class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-header4">Email</h4>
              <input
                type="text"
                class="plexe-form-field bg-white pr-4"
                formControlName="email"
                autocomplete="email"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$"
              />
              <img
                class="position-absolute"
                style="top: 28px; right: 5px;"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="forgotPasswordForm.controls.email.valid"
              />
              <span hidden translate>Email</span>
              <small
                [hidden]="forgotPasswordForm.controls.email.valid || forgotPasswordForm.controls.email.untouched"
                class="text-danger"
                translate
              >
                <div
                  class="text-left"
                  *ngIf="forgotPasswordForm.controls.email.errors && forgotPasswordForm.controls.email.errors.required"
                >
                  Email is required
                </div>
                <div
                  class="text-left"
                  *ngIf="forgotPasswordForm.controls.email.errors && forgotPasswordForm.controls.email.errors.pattern"
                >
                  Email is invalid
                </div>
              </small>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center text-sm-left">
          <button
            class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
            type="submit"
            [disabled]="forgotPasswordForm.invalid || isLoading"
          >
            <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" [hidden]="!isLoading"></i>
            <span translate>Send reset link</span>
          </button>
          <a
            class="d-block forgot-password-link text-decoration-underline text-center font-weight-bold plexe-link-l1"
            routerLink="/login"
            >Back to login</a
          >
        </div>
      </form>
    </div>
    <div [hidden]="!isSmsLogin">
      <div
        class="d-flex flex-column py-0 flex-spacer mt-5 rounded-xl bg-white p-3 p-sm-5 mx-auto"
        style="max-width: 500px;"
      >
        <div class="py-0 px-1 flex-spacer d-flex flex-column overflow-auto">
          <label class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-label-text">SMS Code</label>
          <div class="flex-spacer">
            <div class="d-flex justify-content-around m-auto position-relative" style="max-width: 250px;">
              <div
                class="d-flex mb-4 position-absolute"
                style="left: 50%; top: 15px; transform: translateX(-50%);"
                *ngIf="isLoading"
              >
                <div class="spinner-border m-auto" role="status"></div>
              </div>
              <input
                type="text"
                class="border-0 plexe-bg-light-grey-fix text s-x4 font-weight-bold py-3 rounded text-center plexe-large-number"
                style="width: 48px;"
                maxlength="1"
                id="digit-1"
                mask="0"
                [(ngModel)]="digit1"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp($event, 'digit-2')"
                [disabled]="isLoading"
              />
              <input
                type="text"
                class="border-0 plexe-bg-light-grey-fix text s-x4 font-weight-bold py-3 rounded text-center plexe-large-number"
                style="width: 48px;"
                maxlength="1"
                id="digit-2"
                [(ngModel)]="digit2"
                mask="0"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp($event, 'digit-3', 'digit-1')"
                [disabled]="isLoading"
              />
              <input
                type="text"
                class="border-0 plexe-bg-light-grey-fix text s-x4 font-weight-bold py-3 rounded text-center plexe-large-number"
                style="width: 48px;"
                maxlength="1"
                id="digit-3"
                [(ngModel)]="digit3"
                mask="0"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp($event, 'digit-4', 'digit-2')"
                [disabled]="isLoading"
              />
              <input
                type="text"
                class="border-0 plexe-bg-light-grey-fix text s-x4 font-weight-bold py-3 rounded text-center plexe-large-number"
                style="width: 48px;"
                maxlength="1"
                id="digit-4"
                [(ngModel)]="digit4"
                mask="0"
                (keydown)="onKeyDown($event)"
                (keyup)="onKeyUp($event, null, 'digit-3'); goToComplete($event)"
                [disabled]="isLoading"
              />
            </div>
            <div class="text-center" *ngIf="failed">
              <small class="text-danger">Failed to validate code</small>
            </div>
            <h3 class="text-center mt-4 mb-1 text s-x01 font-weight-bold">
              Didn't receive the code?
              <a
                class="forgot-password-link text-center curs-pointer"
                [ngClass]="!isLoading ? 'plexe-anchor-text-2' : 'plexe-anchor-text-1 pointer-events-none'"
                (click)="resend()"
                >Resend</a
              >
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
