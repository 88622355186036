<app-public-header
  [showLoginButton]="true"
  [showCancelButton]="true"
  [addMobileCss]="true"
  class="d-block"
></app-public-header>
<div
  *ngIf="whitelable"
  class="bg-image-gradient height-100vh with-header overflow-auto p-3 p-sm-5 d-flex flex-column align-items-center justify-content-center"
>
  <!-- CONTENT -->
  <div class="w-100 widescreen-scale">
    <div style="margin-left: 43%;">
      <a class="navbar-brand navbar-brand-mobile" [href]="homeUrl">
        <!--<img [src]="plexeLogoUrl" alt="plexe logo" />-->
        <!-- <img src="assets/logo-line.png" class="logo-line" /> -->
      </a>
    </div>
    <h1 class="mb-1 text-center font-weight-bold plexe-menu-header-2">Welcome back</h1>
    <div
      class="d-flex flex-column py-0 flex-spacer mt-5 rounded-xl bg-white p-3 p-sm-5 mx-auto"
      style="max-width: 500px;"
    >
      <div class="flex-spacer-custom flex-spacer">
        <div class="mb-4 position-relative">
          <button
            class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
            type="button"
            (click)="navigateButton('login')"
          >
            <span translate>Login To Portal</span>
          </button>
        </div>
        <div class="position-relative">
          <button
            class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
            type="button"
            (click)="navigateButton('public')"
          >
            <span translate>Create New Application</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
