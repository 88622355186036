<app-public-header
  [showLoginButton]="true"
  [showCancelButton]="false"
  [addMobileCss]="true"
  class="d-block"
></app-public-header>
<div
  class="bg-image-gradient height-100vh with-header overflow-auto p-3 p-sm-5 d-flex flex-column align-items-center justify-content-center"
>
  <!-- CONTENT -->
  <div class="w-100 widescreen-scale">
    <h1 class="mb-1 text-center font-weight-bold plexe-menu-header-2"></h1>
    <p class="text-center font-weight-bold plexe-menu-header-2">Change your password</p>
    <form (ngSubmit)="resetPassword()" [formGroup]="passwordForm" novalidate>
      <div
        class="d-flex flex-column py-0 flex-spacer mt-5 rounded-xl bg-white p-3 p-sm-5 mx-auto"
        style="max-width: 500px;"
      >
        <div class="alert alert-danger" [hidden]="!error" translate>
          {{ error || 'Login name or password incorrect' }}
        </div>
        <div class="form-group flex-spacer">
          <div class="mb-4 position-relative">
            <h4 class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-header4">New Password</h4>
            <input
              type="password"
              class="plexe-form-field bg-white pr-4"
              formControlName="newpassword"
              autocomplete="new-password"
              required
            />
            <img
              class="position-absolute"
              style="top: 28px; right: 5px;"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="passwordForm.controls.newpassword.valid"
            />
            <span hidden translate>New Password</span>
            <small
              [hidden]="passwordForm.controls.newpassword.valid || passwordForm.controls.newpassword.untouched"
              class="text-danger"
              translate
            >
              New Password is required
            </small>
          </div>
          <div class="position-relative">
            <h4 class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-header4">Confirm Password</h4>
            <input
              type="password"
              class="plexe-form-field bg-white pr-4"
              formControlName="confirmpassword"
              autocomplete="confirm-password"
              required
              (blur)="onBlur()"
            />
            <img
              class="position-absolute"
              style="top: 28px; right: 5px;"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="passwordForm.controls.confirmpassword.valid"
            />
            <span hidden translate>Password</span>
            <small
              [hidden]="passwordForm.controls.confirmpassword.valid || passwordForm.controls.confirmpassword.untouched"
              class="text-danger"
              translate
            >
              Confirm Password is required
            </small>
            <small [hidden]="passwordMatch" class="text-danger" translate>
              Password not matched
            </small>
          </div>
        </div>
      </div>
      <div class="mt-4 text-center text-sm-left">
        <button
          class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
          type="submit"
          [disabled]="passwordForm.invalid || isLoading || !passwordMatch"
        >
          <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" [hidden]="!isLoading"></i>
          <span translate>Change Password</span>
        </button>
        <a
          class="d-block forgot-password-link text-decoration-underline text-center font-weight-bold plexe-link-l1"
          routerLink="/login"
          >Back to login</a
        >
      </div>
    </form>
  </div>
</div>
