export const StatusMap = {
  Draft: {
    displayName: 'Draft',
    linkedUrl: '/how-to-connect',
    toolTip: 'Application has been created, but no banking transactions have been applied'
  },
  CustomerContacted: {
    displayName: 'Customer Contacted',
    linkedUrl: '/how-to-connect',
    toolTip: 'Application has been created, but no banking transactions have been applied'
  },
  // DataProviderConnected: {
  //   displayName: 'Data Provider Connected'
  // },
  OfferReady: {
    displayName: 'Offer Ready',
    linkedUrl: '/thank-you',
    toolTip: 'The application has been reviewed and there is an offer ready to be reviewed by the customer/partner'
  },
  OfferAccepted: {
    displayName: 'Offer Accepted',
    linkedUrl: '/thank-you',
    toolTip: 'The Customer has accepted the offer and added basic details (e.g. update company name)'
  },
  ApplicantDetailsAdded: {
    displayName: 'Application Details Added',
    linkedUrl: '/thank-you'
  },
  BankSetup: {
    displayName: 'Bank Setup',
    linkedUrl: '/thank-you'
  },
  UnderReview: {
    displayName: 'Under Review',
    linkedUrl: '/refer',
    toolTip: 'The application has all the data needed and is being reviewed by our team'
  },
  ApplicationCompleted: {
    displayName: 'Application Completed'
  },
  WaitingForStatements: {
    displayName: 'Waiting For Statements'
  },
  ApplicationSubmitted: {
    displayName: 'Application Submitted'
  },
  ContractComplete: {
    displayName: 'Contract Completed',
    toolTip: 'Customer has signed the contract'
  },
  Cancelled: {
    displayName: 'Cancelled',
    toolTip: `The application was either cancelled by the customer during the sign up process or we cancelled it. For example, It might not have meet our criteria, it was a test application or the customer withdraw`
  },
  WaitingForManualUplaod: {
    displayName: 'Waiting For Manual Upload'
  },
  ITQueueForReview: {
    displayName: 'IT Queue For Review'
  },
  CancelReview: {
    displayName: 'Secondary Review'
  },
  DataProviderConnected: {
    displayName: 'In Progress'
  },
  GeneratingOffer: {
    displayName: 'Generating Offer',
    linkedUrl: '/generating-offer'
  }
};

export const statusOrder = [
  'Cancelled',
  'Draft',
  'CustomerContacted',
  'DataProviderConnected',
  'WaitingForStatements',
  'GeneratingOffer',
  'UnderReview',
  'OfferReady',
  'OfferAccepted',
  'ApplicantDetailsAdded',
  'ApplicationSubmitted',
  'BankSetup',
  'ContractComplete',
  'ApplicationCompleted'
];

export const AllowedPublicStatuses = ['Draft', 'CustomerContacted'];

export const FinishedStatuses = ['ApplicationSubmitted', 'BankSetup', 'ApplicationCompleted'];

export const SignalREvents = {
  ADD_COMMUNICATION: 'AddCommunication',
  REMOVE_COMMUNICATION: 'RemoveCommunication',
  ACCOUNTING_SYSTEM_CONNECTED: 'AccountingSystemConnected',
  ACCOUNTING_SYSTEM_FAILED: 'AccountingSystemFailed',
  ACCOUNTING_PROCESSED_ERROR: 'AccountingProcessedError',
  ACCOUNTING_COMPANY_CONNECTED: 'AccountingCompanySystemConnected',
  ACCOUNTING_DETAILS_CONNECTED: 'AccountingCompanySystemConnectedWithDetails',
  OFFER_COMPLETED: 'OfferCompleted',
  OFFER_FAILED: 'OfferFailed',
  STATEMENT_PROCESS_NOTIFICATION: 'StatementProcessed',
  ACCOUNTING_PROCESS_NOTIFICATION: 'AccountingProcessed',
  DATA_CHANGED: 'AggregateChanged',
  ORGANISATION_CREATED: 'OrganisationCreated',
  ORGANISATION_FAILED: 'OrganisationFailed',
  ACCOUNTING_QUEUE_STARTED: 'AccountingQueueStarted',
  ACCOUNTING_QUEUE_WAIT: 'AccountingQueueWait',
  ENABLE_LOAN: 'EnableLoan',
  NEW_TRANSACTIONS: 'NewTransactions',
  REMOVE_TRANSACTION: 'RemoveTransaction',
  NEW_ADVANCES: 'NewAdvances',
  REMOVE_ADVANCE: 'RemoveAdvance',
  APPLICATION_OFFER_UPDATED: 'ApplicationOfferUpdated',
  UPDATE_LOAN_SETTINGS_SUMMARY: 'UpdateLoanBalanceAndSetting',
  UPDATE_LOAN_SETTINGS: 'UpdateLoanSetting',
  UPDATE_LOAN_BALANCE: 'UpdateLoanBalance',
  APPLICATION_STATUS_CHANGE: 'ApplicationStatusChange',
  ADD_NOTIFICATION: 'AddNotifications',
  BANK_FEED_ACCOUNTS_READY: 'BankingFeedSystemAccountsReady',
  BANK_FEED_MFA_READY: 'BankingFeedSystemMFAReady',
  BANK_FEED_CONNECTED: 'BankingFeedSystemConnected',
  BANK_FEED_FAILED: 'BankingFeedSystemFailed',
  CONTRACT_SIGNED: 'ContractSigned',
  DEBTOR_UPDATED: 'DebtorUpdated',
  REPROCESS_COMPLETE: 'ReprocessComplete',
  APPLICANT_IS_READY_FOR_EVALUATION: 'ApplicantIsReadyForEvaluation',
  AUDIT_INVOICE: 'AuditInvoice',
  UPDATE_CARD_CONFIGURATION: 'UpdateCardConfiguration',
  APPLICATION_CAN_BE_COMPLETED: 'ApplicationCanBeCompleted',
  APPLICATION_CAN_NOT_BE_COMPLETED: 'ApplicationCanNotBeCompleted',
  LOAN_CREATED: 'LoanCreated',
  UPDATED_BUSINESS_NAME_DETAILS: 'UpdateBusinessNameDetails',
  UPDATE_CUSTOMER_PORTAL: 'UpdateCustomerPortal',
  APPLICATION_BUSINESS_UPDATED: 'UpdateApplicationBusiness',
  EMPLOYEE_STATUS_UPDATED: 'EmployeeStatusUpdated',
  UPDATE_INCOME_ON_RECONNECT: 'UpdateIncomeOnReconnect',
  CUSTOMER_CREATED: 'CustomerCreated',
  UPDATE_PARTNER_LIST: 'UpdatePartnerList',
  APPLICATION_PROCESS_UPDATED: 'ApplicationProcessUpdated',
  EMPLOYEE_MENU_ENABLED: 'EmployeeMenuEnabled',
  LOGOUT_CUSTOMER_NOTIFICATION: 'LogoutCustomerNotification',
  DASHBOARD_BANKFEED_ACCOUNTS_UPDATED: 'DashboardBankfeedAccountsUpdated',
  UPLOAD_MISSING_TRANSACTION_COMPLETE: 'UploadMissingTransactionComplete',
  UPLOAD_MISSING_TRANSACTION_FAIL: 'UploadMissingTransactionFail',
  BANK_STATEMENT_MISSING_TRANSACTION_UPDATED: 'BankStatementMissingTransactionUpdated',
  BANKING_FEED_SYSTEM_CONNECTED: 'BankingFeedSystemConnected',
  ADD_UPDATE_PARTNER_COMMUNICATION: 'AddUpdatePartnerCommunication',
  ADD_UPDATE_AGENT_COMMUNICATION: 'AddUpdateAgentCommunication',
  PRIMARY_BANK_ACCOUNT_CHANGED_EVENT: 'PrimaryBankAccountChangedEvent',
  ADD_UPDATE_PARTNER_APPLICATION_COMMUNICATION: 'AddUpdatePartnerApplicationCommunication',
  ADD_UPDATE_AGENT_APPLICATION_COMMUNICATION: 'AddUpdateAgentApplicationCommunication',
  DOCUMENT_UPLOADED_EVENT: 'DocumentUploadedEvent'
};

export const PLEXE_TIMEOUT = 240000;
export const PAGE_SIZE = 13;

export const LEGAL_ENTITY_TYPES = [
  { Value: 'Sole Proprietorship', Text: 'Sole Proprietorship' },
  { Value: 'Partnership', Text: 'Partnership' },
  { Value: 'Limited Liability Company', Text: 'Limited Liability Company' },
  { Value: 'Business Corporation', Text: 'Business Corporation' }
];

export const AU_STATES = [
  { Value: 'New South Wales', Text: 'New South Wales', MinPostCode: 2000, MaxPostCode: 2999 },
  { Value: 'Victoria', Text: 'Victoria', MinPostCode: 3000, MaxPostCode: 3999 },
  { Value: 'Queensland', Text: 'Queensland', MinPostCode: 4000, MaxPostCode: 4999 },
  { Value: 'South Australia', Text: 'South Australia', MinPostCode: 5000, MaxPostCode: 5999 },
  { Value: 'Western Australia', Text: 'Western Australia', MinPostCode: 6000, MaxPostCode: 6999 },
  { Value: 'Tasmania', Text: 'Tasmania', MinPostCode: 7000, MaxPostCode: 7999 }
];

export const US_STATES = [
  { Value: 'Alabama', Text: 'Alabama', MinPostCode: 35003, MaxPostCode: 36926, StateCode: 'AL' },
  { Value: 'Alaska', Text: 'Alaska', MinPostCode: 99500, MaxPostCode: 99951, StateCode: 'AK' },
  { Value: 'Arizona', Text: 'Arizona', MinPostCode: 86557, MaxPostCode: 31999, StateCode: 'AZ' },
  { Value: 'Arkansas', Text: 'Arkansas', MinPostCode: 71600, MaxPostCode: 72960, StateCode: 'AR' },
  { Value: 'California', Text: 'California', MinPostCode: 90000, MaxPostCode: 96163, StateCode: 'CA' },
  { Value: 'Colorado', Text: 'Colorado', MinPostCode: 80000, MaxPostCode: 81659, StateCode: 'CO' },
  { Value: 'Connecticut', Text: 'Connecticut', MinPostCode: 6000, MaxPostCode: 6929, StateCode: 'CT' },
  {
    Value: 'District of Columbia',
    Text: 'District of Columbia',
    MinPostCode: 20001,
    MaxPostCode: 20507,
    StateCode: 'DC'
  },
  { Value: 'Delaware', Text: 'Delaware', MinPostCode: 19700, MaxPostCode: 19981, StateCode: 'DE' },
  { Value: 'Florida', Text: 'Florida', MinPostCode: 32002, MaxPostCode: 34998, StateCode: 'FL' },
  { Value: 'Georgia', Text: 'Georgia', MinPostCode: 30001, MaxPostCode: 39902, StateCode: 'GA' },
  { Value: 'Hawaii', Text: 'Hawaii', MinPostCode: 96700, MaxPostCode: 96899, StateCode: 'HI' },
  { Value: 'Idaho', Text: 'Idaho', MinPostCode: 83200, MaxPostCode: 83878, StateCode: 'ID' },
  { Value: 'Illinois', Text: 'Illinois', MinPostCode: 60000, MaxPostCode: 63000, StateCode: 'IL' },
  { Value: 'Indiana', Text: 'Indiana', MinPostCode: 46000, MaxPostCode: 47998, StateCode: 'IN' },
  { Value: 'Iowa', Text: 'Iowa', MinPostCode: 50000, MaxPostCode: 52810, StateCode: 'IA' },
  { Value: 'Kansas', Text: 'Kansas', MinPostCode: 66001, MaxPostCode: 72960, StateCode: 'KS' },
  { Value: 'Kentucky', Text: 'Kentucky', MinPostCode: 40002, MaxPostCode: 42789, StateCode: 'KY' },
  { Value: 'Louisiana', Text: 'Louisiana', MinPostCode: 70000, MaxPostCode: 71498, StateCode: 'LA' },
  { Value: 'Maine', Text: 'Maine', MinPostCode: 3900, MaxPostCode: 4993, StateCode: 'ME' },
  { Value: 'Maryland', Text: 'Maryland', MinPostCode: 20600, MaxPostCode: 21931, StateCode: 'MD' },
  { Value: 'Massachusetts', Text: 'Massachusetts', MinPostCode: 1000, MaxPostCode: 5545, StateCode: 'MA' },
  { Value: 'Michigan', Text: 'Michigan', MinPostCode: 48000, MaxPostCode: 49972, StateCode: 'MI' },
  { Value: 'Minnesota', Text: 'Minnesota', MinPostCode: 55000, MaxPostCode: 56764, StateCode: 'MN' },
  { Value: 'Mississippi', Text: 'Mississippi', MinPostCode: 38600, MaxPostCode: 39777, StateCode: 'MS' },
  { Value: 'Missouri', Text: 'Missouri', MinPostCode: 63000, MaxPostCode: 65900, StateCode: 'MO' },
  { Value: 'Montana', Text: 'Montana', MinPostCode: 59000, MaxPostCode: 59938, StateCode: 'MT' },
  { Value: 'Nebraska', Text: 'Nebraska', MinPostCode: 68000, MaxPostCode: 69368, StateCode: 'NE' },
  { Value: 'Nevada', Text: 'Nevada', MinPostCode: 88900, MaxPostCode: 89884, StateCode: 'NV' },
  { Value: 'New Hampshire', Text: 'New Hampshire', MinPostCode: 3030, MaxPostCode: 3898, StateCode: 'NH' },
  { Value: 'New Jersey', Text: 'New Jersey', MinPostCode: 7000, MaxPostCode: 8990, StateCode: 'NJ' },
  { Value: 'New Mexico', Text: 'New Mexico', MinPostCode: 87000, MaxPostCode: 88440, StateCode: 'NM' },
  { Value: 'New York', Text: 'New York', MinPostCode: 501, MaxPostCode: 14926, StateCode: 'NY' },
  { Value: 'North Carolina', Text: 'North Carolina', MinPostCode: 27005, MaxPostCode: 28910, StateCode: 'NC' },
  { Value: 'North Dakota', Text: 'North Dakota', MinPostCode: 58000, MaxPostCode: 58857, StateCode: 'ND' },
  { Value: 'Ohio', Text: 'Ohio', MinPostCode: 43000, MaxPostCode: 46000, StateCode: 'OH' },
  { Value: 'Oklahoma', Text: 'Oklahoma', MinPostCode: 73000, MaxPostCode: 74967, StateCode: 'OK' },
  { Value: 'Oregon', Text: 'Oregon', MinPostCode: 97000, MaxPostCode: 97921, StateCode: 'OR' },
  { Value: 'Pennsylvania', Text: 'Pennsylvania', MinPostCode: 15000, MaxPostCode: 19640, StateCode: 'PA' },
  { Value: 'Rhode Island', Text: 'Rhode Island', MinPostCode: 2800, MaxPostCode: 2941, StateCode: 'RI' },
  { Value: 'South Carolina', Text: 'South Carolina', MinPostCode: 29000, MaxPostCode: 29946, StateCode: 'SC' },
  { Value: 'South Dakota', Text: 'South Dakota', MinPostCode: 57000, MaxPostCode: 57800, StateCode: 'SD' },
  { Value: 'Tennessee', Text: 'Tennessee', MinPostCode: 37009, MaxPostCode: 38590, StateCode: 'TN' },
  { Value: 'Texas', Text: 'Texas', MinPostCode: 73300, MaxPostCode: 79000, StateCode: 'TX' },
  { Value: 'Utah', Text: 'Utah', MinPostCode: 84000, MaxPostCode: 84792, StateCode: 'UT' },
  { Value: 'Vermont', Text: 'Vermont', MinPostCode: 5000, MaxPostCode: 5908, StateCode: 'VT' },
  { Value: 'Virginia', Text: 'Virginia', MinPostCode: 20100, MaxPostCode: 26887, StateCode: 'VA' },
  { Value: 'Washington', Text: 'Washington', MinPostCode: 98000, MaxPostCode: 99404, StateCode: 'WA' },
  { Value: 'West Virginia', Text: 'West Virginia', MinPostCode: 24700, MaxPostCode: 26887, StateCode: 'WV' },
  { Value: 'Wisconsin', Text: 'Wisconsin', MinPostCode: 53000, MaxPostCode: 54991, StateCode: 'WI' },
  { Value: 'Wyoming', Text: 'Wyoming', MinPostCode: 82000, MaxPostCode: 83415, StateCode: 'WY' }
];

export const REGION = {
  US: 'US',
  AU: 'AU',
  UK: 'UK',
  NZ: 'NZ'
};

export const DATE_PROPERTY = {
  Communication: 'Communication',
  AxisLabel: 'AxisLabel',
  TransactionDateUTC: 'TransactionDateUTC',
  NextRepaymentDate: 'NextRepaymentDate',
  ScheduleTransaction: 'ScheduleTransaction',
  AdvanceDateUTC: 'AdvanceDateUTC',
  AdvanceNextRepaymentDateUTC: 'AdvanceNextRepaymentDateUTC',
  GroupedAdvancesItemDate: 'GetGroupedAdvancesItemDate',
  CustomerAuditDate: 'CustomerAuditDate',
  CustomerGroupedItemDate: 'CustomerGroupedItemDate',
  DocumentCreatedDate: 'DocumentCreatedDate',
  GroupedTransactionsItemDate: 'GroupedTransactionsItemDate',
  ApplicationLastUpdatedDate: 'ApplicationLastUpdatedDate',
  EmployeeDatetime: 'EmployeeDatetime',
  AgentDate: 'AgentDate'
};

export const REGION_DATE_FORMATS = [
  {
    name: DATE_PROPERTY.Communication,
    formats: { AU: 'MMM DD', US: 'MMM DD' }
  },
  {
    name: DATE_PROPERTY.AxisLabel,
    formats: { AU: 'DD/MM', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.TransactionDateUTC,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.NextRepaymentDate,
    formats: { AU: 'DD MMM YYYY', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.ScheduleTransaction,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.AdvanceDateUTC,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.AdvanceNextRepaymentDateUTC,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.GroupedAdvancesItemDate,
    formats: { AU: 'EEEE d MMMM y', US: 'EEEE d MMMM y' }
  },
  {
    name: DATE_PROPERTY.CustomerAuditDate,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.CustomerGroupedItemDate,
    formats: { AU: 'EEEE d MMMM y', US: 'EEEE d MMMM y' }
  },
  {
    name: DATE_PROPERTY.DocumentCreatedDate,
    formats: { AU: 'D MMMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.GroupedTransactionsItemDate,
    formats: { AU: 'EEEE D MMMM y', US: 'EEEE d MMMM y' }
  },
  {
    name: DATE_PROPERTY.ApplicationLastUpdatedDate,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.EmployeeDatetime,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  },
  {
    name: DATE_PROPERTY.AgentDate,
    formats: { AU: 'D MMM y', US: 'MMM DD, YYYY' }
  }
];

export const REFERRAL_REDIRECT_TIMEOUT = 1000 * 60 * 3;

export const IDLE_THRESHHOLD_TIMEOUT = 1000 * 60 * 30; // 30 minutes
