import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { Router, NavigationEnd, PRIMARY_OUTLET, ActivatedRoute } from '@angular/router';
import { DataState, DataStore } from 'app/DataStore';
import { DATE_PROPERTY, SignalREvents } from 'app/Constants';
import { SignalREvent, SignalrService } from 'app/core/services/signalr.service';
import { Subject, interval } from 'rxjs';
import { filter, takeUntil, map } from 'rxjs/internal/operators';
import { CommunicationProjectionExtension } from '@app/core/proxy/Read/models';
import { getDateAsPerRegion } from '@app/PlexeUtils';
import { ApplicationService } from '@app/core/services/application.service';

export interface IMessage {
  applicationId?: String;
  createdDateUtc?: Date;
  message: String;
  notificationId?: String;
  user: String;
}

@Component({
  selector: 'app-customer-communication-panel',
  templateUrl: './communication-panel.component.html',
  styleUrls: ['./communication-panel.component.scss']
})
export class CustomerCommunicationPanelComponent implements OnInit, AfterViewInit {
  @Input() type = 'loan';
  dataState = DataState;
  isDestroyed = new Subject();
  messages: any[] = [];

  sMessage: string = '';
  isLoading = false;
  oldApplicationId = '';
  private communicationInterval;
  @ViewChild('messagesDiv') messagesDiv: ElementRef;
  messagesDivHeight: number;

  @ViewChildren('messageP') messageParagraphs: QueryList<ElementRef>;

  get loanData() {
    return DataStore.customerData.displayedLoan.details.data;
  }

  get application() {
    return DataStore.application;
  }

  get applicationId() {
    if (this.type == 'loan' && this.loanData) {
      return this.loanData.applicationId;
    } else if (this.application) {
      return this.application.id;
    }
    return null;
  }

  get customerId() {
    if (this.type == 'loan' && this.loanData) {
      return this.loanData.customerId;
    } else if (this.application) {
      return this.application.customerId;
    }
    return null;
  }

  get hasNewCommunication() {
    return DataStore.customerData.hasNewCommunication;
  }

  constructor(
    private signalrService: SignalrService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.route))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        console.log(route.params);
        let routeData = route.snapshot.data;
        this.type = routeData['customerType'] ? routeData['customerType'] : 'loan';

        if (this.applicationId && this.applicationId != this.oldApplicationId) {
          // TODO
          // see also
          this.messages = [];

          this.applicationService
            .loadCommunication(this.applicationId)
            .then((commList: CommunicationProjectionExtension[]) => {
              //update message object with comm data
              //enable button
              if (commList && commList.length > 0) {
                this.messages = commList.sort(
                  (a, b) => new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime()
                );
              }
            });

          this.oldApplicationId = this.applicationId;
        }
      });
  }

  ngAfterViewInit() {
    // this.messagesDivHeight = this.messagesDiv.nativeElement.offsetHeight
    //   ? this.messagesDiv.nativeElement.offsetHeight
    //   : 321;
    // this.messageParagraphs.changes.subscribe(data => {
    //   let messagesToDisplay = 0;
    //   let paragrapsHeightSum = 0;
    //   this.messageParagraphs.forEach(item => {
    //     paragrapsHeightSum += item.nativeElement.offsetHeight + 8;
    //     if (paragrapsHeightSum <= this.messagesDivHeight) {
    //       messagesToDisplay++;
    //     }
    //   });
    //   setTimeout(() => {
    //     if (messagesToDisplay > 0) {
    //       this.messages = this.messages.slice(0, messagesToDisplay);
    //     } else {
    //       this.messages = [];
    //     }
    //   });
    // });
  }

  ngOnInit() {
    this.getCommunicationStatus();
    //use signalr
    this.oldApplicationId = this.applicationId;
    this.isLoading = true;

    if (this.applicationId) {
      this.applicationService
        .loadCommunication(this.applicationId)
        .then((commList: CommunicationProjectionExtension[]) => {
          //update message object with comm data
          //enable button
          this.isLoading = false;
          if (commList && commList.length > 0) {
            this.messages = commList.sort(
              (a, b) => new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime()
            );
          }
        });
    }

    this.communicationInterval = interval(1000000).subscribe(() => {
      if (this.applicationId) {
        this.applicationService.loadCommunication(this.applicationId).then(commList => {
          if (commList) {
            this.messages = [].slice
              .call(commList && commList.length > 0)
              .sort((a, b) => new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime());
          }
        });
      }
    });

    this.signalrService
      .getChannel(SignalREvents.ADD_COMMUNICATION)
      .pipe(
        filter(msg => this.applicationId === msg.data.p1),
        takeUntil(this.isDestroyed)
      )
      .subscribe((msg: SignalREvent) => {
        //disable button
        //clean message object
        this.isLoading = true;

        if (this.applicationId) {
          this.applicationService.loadCommunication(this.applicationId).then(commList => {
            //update message object with comm data
            //enable button
            this.isLoading = false;
            if (commList && commList.length > 0) {
              this.messages = commList.sort(
                (a, b) => new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime()
              );
            }
            // if (this.sMessage && this.sMessage != '') {
            //   this.messages.unshift({ message: this.sMessage, user: 'You' });
            //   this.sMessage = '';
            // }
          });
        }

        this.getCommunicationStatus();
      });

    this.signalrService
      .getChannel(SignalREvents.REMOVE_COMMUNICATION)
      .pipe(
        filter(msg => this.applicationId === msg.data.p1),
        takeUntil(this.isDestroyed)
      )
      .subscribe((msg: SignalREvent) => {
        this.isLoading = true;

        if (this.applicationId) {
          this.applicationService.loadCommunication(this.applicationId).then(commList => {
            this.isLoading = false;
            if (commList && commList.length > 0) {
              this.messages = commList.sort(
                (a, b) => new Date(b.createdDateUtc).getTime() - new Date(a.createdDateUtc).getTime()
              );
            }
          });
        }

        this.getCommunicationStatus();
      });
  }

  sendMessage(message: string) {
    if (message) {
      //change the button to a waiting disabled button
      //show how get the customer login name
      let comm = { message: message, user: 'You' };
      this.isLoading = true;
      this.messages.unshift(comm);
      this.sMessage = '';

      this.applicationService.registerCommunication(this.applicationId, { body: comm }).then(_ => {
        //change button back to ready
        //add message messages variable
        this.isLoading = false;
      });
      //.catch(_ =>  display toast error message );
    }
  }

  getCommunicationDate(dateString) {
    return getDateAsPerRegion(dateString, DATE_PROPERTY.Communication);
  }

  ngOnDestroy() {
    if (this.communicationInterval) {
      this.communicationInterval.unsubscribe();
    }
  }

  getCommunicationStatus() {
    this.applicationService.communicationStatus(this.applicationId).finally(() => (this.isHover = true));
  }

  isHover = true;
  setReadCommunications() {
    if (this.isHover && this.hasNewCommunication) {
      this.isHover = false;
      this.applicationService.readCommunications(this.applicationId).then(() => this.getCommunicationStatus());
    }
  }
  getParsedMessageHtml(message) {
    return `${this.getCommunicationDate(message.createdDateUtc)} [<span>${message.user}</span>]: ${
      message.message != '' ? message.message.replace(/\n/g, '<br>') : ''
    }`;
  }
}
