/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const accountingPackage: msRest.OperationURLParameter = {
  parameterPath: 'accountingPackage',
  mapper: {
    required: true,
    serializedName: 'accountingPackage',
    type: {
      name: 'String'
    }
  }
};
export const addFacilityLimit: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'addFacilityLimit'],
  mapper: {
    serializedName: 'addFacilityLimit',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const advanceId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'advanceId'],
  mapper: {
    serializedName: 'AdvanceId',
    type: {
      name: 'Uuid'
    }
  }
};
export const advanceId1: msRest.OperationURLParameter = {
  parameterPath: 'advanceId',
  mapper: {
    required: true,
    serializedName: 'advanceId',
    type: {
      name: 'Uuid'
    }
  }
};
export const advanceId2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'advanceId'],
  mapper: {
    serializedName: 'advanceId',
    type: {
      name: 'Uuid'
    }
  }
};
export const agentId0: msRest.OperationURLParameter = {
  parameterPath: 'agentId',
  mapper: {
    required: true,
    serializedName: 'agentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const agentId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'agentId'],
  mapper: {
    serializedName: 'agentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const all: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'all'],
  mapper: {
    serializedName: 'all',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const amount0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'amount'],
  mapper: {
    serializedName: 'amount',
    type: {
      name: 'Number'
    }
  }
};
export const amount1: msRest.OperationQueryParameter = {
  parameterPath: 'amount',
  mapper: {
    required: true,
    serializedName: 'amount',
    type: {
      name: 'Number'
    }
  }
};
export const appID: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'appID'],
  mapper: {
    serializedName: 'appID',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicantId0: msRest.OperationURLParameter = {
  parameterPath: 'applicantId',
  mapper: {
    required: true,
    serializedName: 'applicantId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicantId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'applicantId'],
  mapper: {
    serializedName: 'applicantId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicationId0: msRest.OperationURLParameter = {
  parameterPath: 'applicationId',
  mapper: {
    required: true,
    serializedName: 'applicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicationId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'applicationId'],
  mapper: {
    serializedName: 'applicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const autoCancel: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'autoCancel'],
  mapper: {
    serializedName: 'autoCancel',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const businessName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'businessName'],
  mapper: {
    serializedName: 'businessName',
    type: {
      name: 'String'
    }
  }
};
export const cancelledDays: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'cancelledDays'],
  mapper: {
    serializedName: 'cancelledDays',
    defaultValue: 5,
    type: {
      name: 'Number'
    }
  }
};
export const charList: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'charList'],
  mapper: {
    serializedName: 'charList',
    type: {
      name: 'String'
    }
  }
};
export const code: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'code'],
  mapper: {
    serializedName: 'code',
    type: {
      name: 'String'
    }
  }
};
export const column: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'column'],
  mapper: {
    serializedName: 'column',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const commissionRate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'commissionRate'],
  mapper: {
    serializedName: 'commissionRate',
    type: {
      name: 'Number'
    }
  }
};
export const criteria: msRest.OperationQueryParameter = {
  parameterPath: 'criteria',
  mapper: {
    required: true,
    serializedName: 'criteria',
    type: {
      name: 'String'
    }
  }
};
export const customerId0: msRest.OperationURLParameter = {
  parameterPath: 'customerId',
  mapper: {
    required: true,
    serializedName: 'customerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const customerId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'customerId'],
  mapper: {
    serializedName: 'customerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const dateParameter: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'dateParameter'],
  mapper: {
    serializedName: 'date',
    type: {
      name: 'DateTime'
    }
  }
};
export const days: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'days'],
  mapper: {
    serializedName: 'days',
    defaultValue: 21,
    type: {
      name: 'Number'
    }
  }
};
export const debtor: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'debtor'],
  mapper: {
    serializedName: 'debtor',
    type: {
      name: 'String'
    }
  }
};
export const direct: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'direct'],
  mapper: {
    serializedName: 'direct',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const direction: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'direction'],
  mapper: {
    serializedName: 'direction',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const documentId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'documentId'],
  mapper: {
    serializedName: 'documentId',
    type: {
      name: 'String'
    }
  }
};
export const documentId1: msRest.OperationURLParameter = {
  parameterPath: 'documentId',
  mapper: {
    required: true,
    serializedName: 'documentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const email0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'email'],
  mapper: {
    serializedName: 'email',
    type: {
      name: 'String'
    }
  }
};
export const email1: msRest.OperationURLParameter = {
  parameterPath: 'email',
  mapper: {
    required: true,
    serializedName: 'email',
    type: {
      name: 'String'
    }
  }
};
export const endDate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'endDate'],
  mapper: {
    serializedName: 'endDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const establishmentFeeRate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'establishmentFeeRate'],
  mapper: {
    serializedName: 'establishmentFeeRate',
    type: {
      name: 'Number'
    }
  }
};
export const excludeCreditCards: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'excludeCreditCards'],
  mapper: {
    serializedName: 'excludeCreditCards',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const externalSubmitted: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'externalSubmitted'],
  mapper: {
    serializedName: 'externalSubmitted',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const fileName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fileName'],
  mapper: {
    serializedName: 'fileName',
    type: {
      name: 'String'
    }
  }
};
export const fileType: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fileType'],
  mapper: {
    serializedName: 'fileType',
    type: {
      name: 'String'
    }
  }
};
export const filterColumn: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'filterColumn'],
  mapper: {
    serializedName: 'filterColumn',
    type: {
      name: 'String'
    }
  }
};
export const filterValue: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'filterValue'],
  mapper: {
    serializedName: 'filterValue',
    type: {
      name: 'String'
    }
  }
};
export const fixedRepaymentCalculation: msRest.OperationQueryParameter = {
  parameterPath: 'fixedRepaymentCalculation',
  mapper: {
    required: true,
    serializedName: 'fixedRepaymentCalculation',
    type: {
      name: 'Boolean'
    }
  }
};
export const force: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'force'],
  mapper: {
    serializedName: 'force',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const host: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'host'],
  mapper: {
    serializedName: 'host',
    type: {
      name: 'String'
    }
  }
};
export const id0: msRest.OperationURLParameter = {
  parameterPath: 'id',
  mapper: {
    required: true,
    serializedName: 'id',
    type: {
      name: 'Number'
    }
  }
};
export const id1: msRest.OperationQueryParameter = {
  parameterPath: 'id',
  mapper: {
    required: true,
    serializedName: 'id',
    type: {
      name: 'Uuid'
    }
  }
};
export const id2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'id'],
  mapper: {
    serializedName: 'Id',
    type: {
      name: 'Uuid'
    }
  }
};
export const id3: msRest.OperationURLParameter = {
  parameterPath: 'id',
  mapper: {
    required: true,
    serializedName: 'Id',
    type: {
      name: 'Uuid'
    }
  }
};
export const includeCancelled: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'includeCancelled'],
  mapper: {
    serializedName: 'includeCancelled',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const includedClosed: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'includedClosed'],
  mapper: {
    serializedName: 'includedClosed',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const ipAddress: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'ipAddress'],
  mapper: {
    serializedName: 'ipAddress',
    type: {
      name: 'String'
    }
  }
};
export const isAgent: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isAgent'],
  mapper: {
    serializedName: 'isAgent',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isCustomer: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isCustomer'],
  mapper: {
    serializedName: 'isCustomer',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isNew: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isNew'],
  mapper: {
    serializedName: 'isNew',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const isPartner: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isPartner'],
  mapper: {
    serializedName: 'isPartner',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isReset: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isReset'],
  mapper: {
    serializedName: 'isReset',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isSecondary: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isSecondary'],
  mapper: {
    serializedName: 'isSecondary',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const loanId0: msRest.OperationURLParameter = {
  parameterPath: 'loanId',
  mapper: {
    required: true,
    serializedName: 'loanId',
    type: {
      name: 'Uuid'
    }
  }
};
export const loanId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loanId'],
  mapper: {
    serializedName: 'loanId',
    type: {
      name: 'Uuid'
    }
  }
};
export const loanType: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loanType'],
  mapper: {
    serializedName: 'loanType',
    type: {
      name: 'Number'
    }
  }
};
export const location: msRest.OperationURLParameter = {
  parameterPath: 'location',
  mapper: {
    required: true,
    serializedName: 'location',
    type: {
      name: 'String'
    }
  }
};
export const mobile: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'mobile'],
  mapper: {
    serializedName: 'mobile',
    type: {
      name: 'String'
    }
  }
};
export const monthRange: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'monthRange'],
  mapper: {
    serializedName: 'monthRange',
    type: {
      name: 'Number'
    }
  }
};
export const name: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'name'],
  mapper: {
    serializedName: 'name',
    type: {
      name: 'String'
    }
  }
};
export const objectType: msRest.OperationQueryParameter = {
  parameterPath: 'objectType',
  mapper: {
    required: true,
    serializedName: 'objectType',
    type: {
      name: 'String'
    }
  }
};
export const offerId0: msRest.OperationURLParameter = {
  parameterPath: 'offerId',
  mapper: {
    required: true,
    serializedName: 'offerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const offerId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'offerId'],
  mapper: {
    serializedName: 'offerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const onlyKnownDebtors: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'onlyKnownDebtors'],
  mapper: {
    serializedName: 'onlyKnownDebtors',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const onlyUnread: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'onlyUnread'],
  mapper: {
    serializedName: 'onlyUnread',
    type: {
      name: 'Boolean'
    }
  }
};
export const orderBy0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'orderBy'],
  mapper: {
    serializedName: 'orderBy',
    type: {
      name: 'String'
    }
  }
};
export const orderBy1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'orderBy'],
  mapper: {
    serializedName: 'orderBy',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const orderBy2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'orderBy'],
  mapper: {
    serializedName: 'orderBy',
    defaultValue: 'BusinessName',
    type: {
      name: 'String'
    }
  }
};
export const orderbyDescending: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'orderbyDescending'],
  mapper: {
    serializedName: 'orderbyDescending',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const organisation: msRest.OperationURLParameter = {
  parameterPath: 'organisation',
  mapper: {
    required: true,
    serializedName: 'organisation',
    type: {
      name: 'String'
    }
  }
};
export const organisationId0: msRest.OperationURLParameter = {
  parameterPath: 'organisationId',
  mapper: {
    required: true,
    serializedName: 'organisationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const organisationId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'organisationId'],
  mapper: {
    serializedName: 'organisationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const otp: msRest.OperationQueryParameter = {
  parameterPath: 'otp',
  mapper: {
    required: true,
    serializedName: 'otp',
    type: {
      name: 'String'
    }
  }
};
export const overrideCalculation: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'overrideCalculation'],
  mapper: {
    serializedName: 'overrideCalculation',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const partnerId0: msRest.OperationURLParameter = {
  parameterPath: 'partnerId',
  mapper: {
    required: true,
    serializedName: 'partnerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const partnerId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'partnerId'],
  mapper: {
    serializedName: 'partnerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const partnerId2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'partnerId'],
  mapper: {
    serializedName: 'partnerId',
    defaultValue: '207e00c6-6229-4d6c-9f47-5a085000225b',
    type: {
      name: 'String'
    }
  }
};
export const password: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'password'],
  mapper: {
    serializedName: 'password',
    type: {
      name: 'String'
    }
  }
};
export const percent: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'percent'],
  mapper: {
    serializedName: 'percent',
    type: {
      name: 'Number'
    }
  }
};
export const percentageOfIncome: msRest.OperationQueryParameter = {
  parameterPath: 'percentageOfIncome',
  mapper: {
    required: true,
    serializedName: 'percentageOfIncome',
    type: {
      name: 'Number'
    }
  }
};
export const phone: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'phone'],
  mapper: {
    serializedName: 'phone',
    type: {
      name: 'String'
    }
  }
};
export const redirectUri: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'redirectUri'],
  mapper: {
    serializedName: 'redirectUri',
    defaultValue: 'portal.plexe.co',
    type: {
      name: 'String'
    }
  }
};
export const refresh: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'refresh'],
  mapper: {
    serializedName: 'refresh',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const requestId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'requestId'],
  mapper: {
    serializedName: 'requestId',
    type: {
      name: 'Uuid'
    }
  }
};
export const requestId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'requestId'],
  mapper: {
    serializedName: 'requestId',
    type: {
      name: 'String'
    }
  }
};
export const reset: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'reset'],
  mapper: {
    serializedName: 'reset',
    type: {
      name: 'Boolean'
    }
  }
};
export const ruleName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'ruleName'],
  mapper: {
    serializedName: 'ruleName',
    type: {
      name: 'String'
    }
  }
};
export const search0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'search'],
  mapper: {
    serializedName: 'search',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const search1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'search'],
  mapper: {
    serializedName: 'search',
    type: {
      name: 'String'
    }
  }
};
export const searchText: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'searchText'],
  mapper: {
    serializedName: 'searchText',
    type: {
      name: 'String'
    }
  }
};
export const secured: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'secured'],
  mapper: {
    serializedName: 'secured',
    type: {
      name: 'Number'
    }
  }
};
export const shortCode0: msRest.OperationURLParameter = {
  parameterPath: 'shortCode',
  mapper: {
    required: true,
    serializedName: 'shortCode',
    type: {
      name: 'String'
    }
  }
};
export const shortCode1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'shortCode'],
  mapper: {
    serializedName: 'shortCode',
    type: {
      name: 'String'
    }
  }
};
export const showAll: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'showAll'],
  mapper: {
    serializedName: 'showAll',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const showCancel: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'showCancel'],
  mapper: {
    serializedName: 'showCancel',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const showCancelled: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'showCancelled'],
  mapper: {
    serializedName: 'showCancelled',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const showDeleted: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'showDeleted'],
  mapper: {
    serializedName: 'showDeleted',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const showPaymentRequests: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'showPaymentRequests'],
  mapper: {
    serializedName: 'showPaymentRequests',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const skip0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'skip'],
  mapper: {
    serializedName: 'skip',
    defaultValue: 0,
    type: {
      name: 'Number'
    }
  }
};
export const skip1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'skip'],
  mapper: {
    serializedName: 'skip',
    type: {
      name: 'Number'
    }
  }
};
export const startDate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'startDate'],
  mapper: {
    serializedName: 'startDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const status0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'status'],
  mapper: {
    serializedName: 'status',
    type: {
      name: 'String'
    }
  }
};
export const status1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'status'],
  mapper: {
    serializedName: 'status',
    defaultValue: 'Draft',
    type: {
      name: 'String'
    }
  }
};
export const take0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'take'],
  mapper: {
    serializedName: 'take',
    defaultValue: 2147483647,
    type: {
      name: 'Number'
    }
  }
};
export const take1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'take'],
  mapper: {
    serializedName: 'take',
    type: {
      name: 'Number'
    }
  }
};
export const templateId: msRest.OperationURLParameter = {
  parameterPath: 'templateId',
  mapper: {
    required: true,
    serializedName: 'templateId',
    type: {
      name: 'Uuid'
    }
  }
};
export const term: msRest.OperationQueryParameter = {
  parameterPath: 'term',
  mapper: {
    required: true,
    serializedName: 'term',
    type: {
      name: 'Number'
    }
  }
};
export const terms: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'terms'],
  mapper: {
    serializedName: 'terms',
    type: {
      name: 'Number'
    }
  }
};
export const transactionId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'transactionId'],
  mapper: {
    serializedName: 'transactionId',
    type: {
      name: 'Uuid'
    }
  }
};
export const typeName: msRest.OperationQueryParameter = {
  parameterPath: 'typeName',
  mapper: {
    required: true,
    serializedName: 'typeName',
    type: {
      name: 'String'
    }
  }
};
export const url: msRest.OperationQueryParameter = {
  parameterPath: 'url',
  mapper: {
    required: true,
    serializedName: 'url',
    type: {
      name: 'String'
    }
  }
};
export const urlReferral: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'urlReferral'],
  mapper: {
    serializedName: 'urlReferral',
    type: {
      name: 'String'
    }
  }
};
export const userId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'userId'],
  mapper: {
    serializedName: 'userId',
    type: {
      name: 'Uuid'
    }
  }
};
export const userName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'userName'],
  mapper: {
    serializedName: 'userName',
    type: {
      name: 'String'
    }
  }
};
export const viewType: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'viewType'],
  mapper: {
    serializedName: 'viewType',
    defaultValue: 'All',
    type: {
      name: 'String'
    }
  }
};
export const yodlee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'yodlee'],
  mapper: {
    serializedName: 'yodlee',
    type: {
      name: 'Boolean'
    }
  }
};
export const zipCode: msRest.OperationURLParameter = {
  parameterPath: 'zipCode',
  mapper: {
    required: true,
    serializedName: 'zipCode',
    type: {
      name: 'Number'
    }
  }
};
