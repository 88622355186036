<!-- <app-system-notification></app-system-notification> -->
<app-public-header
  [showLoginButton]="true"
  [showCancelButton]="false"
  [addMobileCss]="true"
  class="d-block"
></app-public-header>
<div
  class="bg-image-gradient height-100vh with-header overflow-auto p-3 p-sm-5 d-flex flex-column align-items-center justify-content-center"
>
  <!-- CONTENT -->
  <div class="w-100 widescreen-scale">
    <h1 class="mb-1 text-center font-weight-bold plexe-menu-header-2">Welcome back</h1>
    <p class="text-center font-weight-bold plexe-menu-header-2">Login to your account</p>
    <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
      <div
        class="d-flex flex-column py-0 flex-spacer mt-5 rounded-xl bg-white p-3 p-sm-5 mx-auto"
        style="max-width: 500px;"
      >
        <div class="alert alert-danger" [hidden]="!error" translate>
          {{ error || 'Login name or password incorrect' }}
        </div>
        <div class="form-group flex-spacer">
          <div class="mb-4 position-relative">
            <h4 class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-header4">Email</h4>
            <input
              type="text"
              class="plexe-form-field bg-white pr-4"
              formControlName="username"
              autocomplete="username"
              (input)="trim($event)"
            />
            <i
              class="position-absolute fa fa-spinner fa-spin mr-1"
              style="top: 24px;"
              [hidden]="!userLoading && !loggedInLoading"
              aria-hidden="true"
            ></i>
            <img
              class="position-absolute"
              style="top: 28px; right: 5px;"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="loginForm.controls.username.valid"
            />
            <span hidden translate>Email</span>
            <small
              [hidden]="loginForm.controls.username.valid || loginForm.controls.username.untouched"
              class="text-danger"
              translate
            >
              Email is required
            </small>
          </div>
          <div class="position-relative">
            <h4 class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-header4">Password</h4>
            <input
              type="password"
              class="plexe-form-field bg-white pr-4"
              formControlName="password"
              autocomplete="current-password"
              required
              [disabled]="userLoading || loggedInLoading"
            />
            <img
              class="position-absolute"
              style="top: 28px; right: 5px;"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="loginForm.controls.password.valid"
            />
            <span hidden translate>Password</span>
            <small
              [hidden]="
                ((isUserTypeEmployee || isUserTypeCorprate) && !hasLoggedIn) ||
                loginForm.controls.password.valid ||
                loginForm.controls.password.untouched
              "
              class="text-danger"
              translate
            >
              Password is required
            </small>
          </div>
        </div>
      </div>
      <div class="mt-4 text-center text-sm-left">
        <a
          class="d-block text-decoration-underline text-center font-weight-bold plexe-link-l1 mb-3"
          routerLink="/login-sms"
          >Login with SMS?</a
        >
        <button
          class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
          type="submit"
          [disabled]="loginForm.invalid || isLoading || isRedirecting"
        >
          <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" [hidden]="!isLoading && !isRedirecting"></i>
          <span translate>{{ loginButtonText }}</span>
        </button>
        <a
          class="d-block forgot-password-link text-decoration-underline text-center font-weight-bold plexe-link-l1"
          routerLink="/forgot-password"
          >Forgot password?</a
        >
      </div>
    </form>
  </div>
</div>
